import React, { createElement } from 'react';
import css from './UserEarningsField.module.scss';

export type UserEarningsFieldProps = {
  title: string,
  value: string,
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
}

export function UserEarningsField(props: UserEarningsFieldProps) {
  const { title, value, as = 'h4' } = props;
  const valueElement = createElement(as, null, value);
  return (
    <div className={css.container}>
      <div className={css.title}>
        {title}
      </div>
      <div className={css.value}>
        {valueElement}
      </div>
    </div>
  );
}
