import React from 'react';
import {
  Alert, Badge, Col, Row,
} from 'react-bootstrap';
import { UserProfile } from '../../../../dorian-shared/types/userProfile/UserProfile';
import { Divider } from '../../../uiV2/Divider/Divider';
import { UserEarningsField } from '../UserEarnings/UserEarningsField/UserEarningsField';

export type UserGamesProps = {
  user: UserProfile,
}

export function UserGames(props: UserGamesProps) {
  const { user } = props;

  const books = user.books ? user.books : [];
  const releasedEpisodes = new Intl.NumberFormat().format(user.releasedEpisodes ?? 0);
  const newReleasedEpisodes = new Intl.NumberFormat().format(user.newReleasedEpisodes ?? 0);
  const totalReads = user.stats?.total_reads ?? 0;
  const singlePlayerReads = typeof totalReads === 'number'
    ? new Intl.NumberFormat().format(totalReads ?? 0)
    : '0';

  return (
    <>
      <h4 className="text-left w-100">GAMES</h4>
      <Row>
        <Col>
          <UserEarningsField as="h2" title="RELEASED EPISODES" value={releasedEpisodes} />
        </Col>
        <Col>
          <UserEarningsField as="h2" title="NEW EPISODES (LAST 30 DAYS)" value={newReleasedEpisodes} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserEarningsField title="SINGLE PLAYER READS" value={singlePlayerReads} />
        </Col>
      </Row>
      <Divider margin="0 0 8px 0" />
      <h6 className="text-left w-100">MY GAMES</h6>
      <div className="pt-2 listChaptersBox listChaptersBox-page">
        {books.map((book) => (
          <div
            key={book.id}
            className="p-0 my-1"
          >
            <Alert.Link
              href={`/book/${book.id}`}
              className="px-3 py-1 d-block"
            >
              {book.title}
              {book.isPrompt
                && <Badge variant="secondary" style={{ marginLeft: '.5em' }}>Prompt</Badge>}
            </Alert.Link>
          </div>
        ))}
      </div>
    </>
  );
}
