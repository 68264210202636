import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import sapphireIcon from '../../../../assets/images/ui/icon-sapphire.png';
import { logger } from '../../../../services/loggerService/loggerService';
import { BranchAnalytics } from '../BranchAnalytics/BranchAnalytics';
import classNames from './BranchNodeCardWithAnalytics.module.scss';
import { TraitImages } from './TraitImages';

let analyticPosition = { top: 0, left: 0 };

const getAnalyticsCount = (analytics, property) => {
  if (!analytics) {
    logger.info('analytics is null');
    return 0;
  }
  switch (property) {
    case 'users':
      return analytics.branch_users ?? 0;
    case 'dropOff':
      if (!analytics.branch_dropoff) {
        return 0;
      }
      if (analytics.branch_dropoff > 100) {
        return 100;
      }
      return analytics.branch_dropoff;
    case 'hearts':
      return analytics.branch_hearts ?? 0;
    case 'sapphires':
      return analytics.branch_sapphires ?? 0;
    default:
      return 0;
  }
};

export function BranchNodeCardWithAnalytics({
  branchNode,
}) {
  const [isShowBranchAnalytics, setIsShowBranchAnalytics] = useState(false);

  const handleMouseEnter = (e) => {
    // The entire canvas is scaled by zoom.
    const zoom = parseFloat(localStorage.getItem('zoom') ?? 1);
    const ancestor = e.currentTarget;
    const rects = ancestor?.getClientRects();
    const top = rects[0].height / (zoom * 3);
    const left = rects[0].width / zoom + 80;

    analyticPosition = { top, left };
    setIsShowBranchAnalytics(true);
  };

  const handleMouseOut = () => setIsShowBranchAnalytics(false);

  const analytics = branchNode.analytics || null;
  const decisionPoint = branchNode.links?.length > 0;
  const heartsCount = new Intl.NumberFormat().format(getAnalyticsCount(analytics, 'hearts'));
  const sapphireCount = new Intl.NumberFormat().format(getAnalyticsCount(analytics, 'sapphires'));

  return (
    <div
      className={classNames.dropOffContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOut}
    >
      <div>
        Users:
        {` ${getAnalyticsCount(analytics, 'users')}`}
      </div>
      <br />
      <div className={classNames.dropOff}>
        <span className="dropOff-span">
          {`${getAnalyticsCount(analytics, 'dropOff')}%`}
        </span>
      </div>
      <div>
        Drop-Off Rate
      </div>
      <br />
      {decisionPoint && (
      <>
        <Row className={classNames.decisionPointRow}>
          <Col sm={5} className={classNames.decisionPointCol} style={{ padding: 0 }}>
            ❤️
          </Col>
          <Col sm={7} className={classNames.decisionPointCount}>
            {heartsCount}
          </Col>
        </Row>
        <Row className={classNames.decisionPointRow}>
          <Col sm={5} className={classNames.decisionPointCol}>
            <div className={classNames.decisionPointIcon}>
              <img src={sapphireIcon} alt="sapphire" />
            </div>
          </Col>
          <Col sm={7} className={classNames.decisionPointCount}>
            {sapphireCount}
          </Col>
        </Row>
        <TraitImages
          answers={branchNode.answers}
        />
      </>
      )}
      {analytics && isShowBranchAnalytics && (
      <div className={classNames.branchAnalytics} style={analyticPosition}>
        <BranchAnalytics
          steps={analytics.steps}
        />
      </div>
      )}
    </div>
  );
}
