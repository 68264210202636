import React from 'react';
import { BranchNodeCard } from './BranchNodeCards/BranchNodeCard';
import { BranchNodeCardWithAnalytics } from './BranchNodeCards/BranchNodeCardWithAnalytics';

export function BranchContent({
  branchNode,
  isAnalyticsVisible,
  storyGroup,
  memorySlotData,
}) {
  if (isAnalyticsVisible) {
    return (
      <BranchNodeCardWithAnalytics
        branchNode={branchNode}
      />
    );
  }

  return (
    <BranchNodeCard
      storyGroup={storyGroup}
      branchNode={branchNode}
      memorySlotData={memorySlotData}
    />
  );
}
