import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserProfile } from '../../../../dorian-shared/types/userProfile/UserProfile';
import { Divider } from '../../../uiV2/Divider/Divider';
import Earnings from '../../EarningsModal/Earnings';
import { UserEarningsField } from './UserEarningsField/UserEarningsField';
import { UserEarningsHeader } from './UserEarningsHeader/UserEarningsHeader';

export type UserEarningsProps = {
  user: UserProfile,
}

export function UserEarnings(props: UserEarningsProps) {
  const {
    user,
  } = props;

  const [showEarningsPopup, setShowEarningsPopup] = useState(false);

  const estimatedHeartsEarned = new Intl.NumberFormat().format(user?.hearts ? user?.hearts?.estimatedHeartsEarned : 0);
  const heartsPending = new Intl.NumberFormat().format(user?.hearts ? user?.hearts?.heartsPending : 0);
  const heartsPayable = new Intl.NumberFormat().format(user?.hearts ? user?.hearts?.heartsPayable : 0);
  const heartsReviewing = new Intl.NumberFormat().format(user?.hearts ? user?.hearts?.heartsReviewing : 0);
  const heartsPaid = new Intl.NumberFormat().format(user?.hearts ? user?.hearts?.heartsPaid : 0);
  const sapphiresSpent = new Intl.NumberFormat().format(user?.sapphires?.earnedThisMonth ?? 0);

  return (
    <>
      <UserEarningsHeader onClick={() => setShowEarningsPopup(true)} />
      <Row>
        <Col>
          <UserEarningsField as="h2" title="❤️ESTIMATED HEARTS THIS MONTH:" value={estimatedHeartsEarned} />
        </Col>
      </Row>
      <Divider margin="0 0 8px 0" />
      <Row>
        <Col>
          <UserEarningsField title="❤️HEARTS PENDING PAYMENT:" value={heartsPending} />
        </Col>
        <Col>
          <UserEarningsField title="❤️HEARTS UNDER THRESHOLD:" value={heartsPayable} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserEarningsField title="❤️HEARTS IN REVIEW:" value={heartsReviewing} />
        </Col>
        <Col>
          <UserEarningsField title="❤️LIFETIME HEARTS PAID OUT:" value={heartsPaid} />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserEarningsField title="💎SAPPHIRES THIS MONTH:" value={sapphiresSpent} />
        </Col>
      </Row>

      <i>
        <Col>Dorian splits each dollar spent on hearts in your stories 50/50</Col>
        <Col>You can cash out when you reach a threshold of 500 hearts</Col>
      </i>

      {showEarningsPopup && (
        <Earnings
          user={{
            hearts: {
              estimatedHeartsEarned: user.hearts ? user.hearts.estimatedHeartsEarned : 0,
              heartsPayable: user.hearts ? user.hearts.heartsPayable : 0,
            },
          }}
          transactions={user.hearts ? user.hearts.monthlyBreakdown : []}
          show={showEarningsPopup}
          onClose={() => setShowEarningsPopup(false)}
          parentModal={undefined}
        />
      )}
    </>
  );
}
