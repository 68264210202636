import React from 'react';
import { Button } from 'react-bootstrap';
import infoIcon from '../../../../../assets/images/info.svg';

export type UserEarningsHeaderProps = {
  onClick: () => void;
}

export function UserEarningsHeader(props: UserEarningsHeaderProps) {
  const { onClick } = props;
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <h4 className="text-left w-100">EARNINGS</h4>
      <Button
        onClick={onClick}
        variant="link"
        size="sm"
        className="mx-1"
      >
        <img src={infoIcon} className="btnImg" alt="Earnings Breakdown" />
      </Button>
    </div>
  );
}
