import React from 'react';

export type DividerProps = {
  color?: string;
  margin?: string;
  height?: string;
}

export function Divider(props: DividerProps) {
  const { color, margin, height } = props;

  return (
    <div
      style={
     {
       margin: margin || '0',
     }
   }
    >
      <div
        style={
        {
          background: color || 'black',
          height: height || '1px',
        }
      }
      />
    </div>
  );
}
